/**
 * NOTE:
 * app and output are global variables that represent namespaces and should 
 * not be overriden!!!
 */

(function () {
    "use strict";

    angular.module('dcms.login', [
        'ngAnimate',
        'ngMap',
        'pascalprecht.translate',
        'litui'
    ]).config(config).run(run);

    /** @ngInject */
    function config($translateProvider, config) {        
        // --- CONFIGURE TRANSLATIONS ---        
        $translateProvider.useStaticFilesLoader({
            prefix: '../rest/app-translation/load/login/',
            suffix: ''
        });
        $translateProvider.preferredLanguage(config.language)
                .fallbackLanguage('en');
        
        tmsBaseStyle.applyFontFamily(config.fontFamily);
    }

    /** @ngInject */
    function run(config, $q, $timeout, $rootScope) {
        
        // manually bootstrap LITUI
//        litui.logger.level(litui.logger.Levels.ALL);
        litui.config.set(config);
        litui.async.setQ($q);
        litui.request.settings('../');
        litui.async.setTimeoutProvider({
            set: function(callback, duration){
                return $timeout(callback, duration);
            }
        });
        litui.security.setPasswordPattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/);
        
//        litui.translator.setProvider({
//            translate: function(str, params){
//                return translate(str, params);
//            }
//        });
    }

})();